import Link from 'next/link';
import { Head } from '../components/Head';
import { withColorMode } from '../lib/color-mode';

export default function Home(): React.ReactElement {
  return (
    <>
      <Head
        title="Rolf Erik Lekang"
        description="I am an independent software developer who enjoys working with the whole stack. I occationally write posts, take photographs, ride mountain bikes and snowboards. I do consulting work, if that is something you are looking I might be able to help out."
        url="/"
      />
      <p>
        I am an independent software developer who enjoys working with the whole
        stack. I occationally <Link href="/writing">write blog posts</Link>,{' '}
        <Link href="/til">
          write tiny <abbr title="Today I learned">TIL</abbr> posts
        </Link>
        , <Link href="/photography">take photos</Link>, ride mountain bikes and
        snowboards. I do consulting work, if that is something you are looking I
        might be <Link href="/consulting">able to help out</Link>.
      </p>
    </>
  );
}

export const getServerSideProps = withColorMode<{
  landing: true;
}>(async () => {
  return { props: { landing: true } };
});
