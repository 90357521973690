import NextHead from 'next/head';
function prefixUrl(url: string) {
  return typeof window === 'undefined'
    ? `https://rolflekang.com${url}`
    : window.location.origin + url;
}

export const Head = ({
  title,
  description,
  url,
  image,
  twitterCard,
}: {
  title: string;
  description?: string;
  url: string;
  image?: string;
  twitterCard?: string;
}) => {
  const canonical = prefixUrl(url);
  return (
    <NextHead>
      <title>{title}</title>
      <link rel="canonical" href={canonical} />

      <meta content={title} property="og:title" />
      <meta content={title} name="twitter:title" />
      <meta content="@relekang" name="twitter:author" />
      <meta content={twitterCard || 'summary'} name="twitter:card" />
      <meta property="og:url" content={canonical} />
      {image ? (
        <>
          <meta property="og:image" content={prefixUrl(image)} />
          <meta name="twitter:image:src" content={prefixUrl(image)} />
        </>
      ) : null}
      {description ? (
        <>
          <meta content={description} property="description" />
          <meta content={description} property="og:description" />
          <meta content={description} name="twitter:description" />
        </>
      ) : null}
    </NextHead>
  );
};
